import { Link, useRouteError } from 'react-router-dom';
import { Box, Container, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography variant="h1">400</Typography>
            <Typography variant="h6">
              Sorry, an unexpected error has occurred.
            </Typography>
            <Typography variant="h6">
              {error?.statusText || error?.message}
            </Typography>
            <Link to="/" relative="path">
              Back Home
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
