import { PaletteMode } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    brand200: { main: string };
    neutral500: { main: string };
    elevation01: { main: string };
    elevation02: { main: string };
    signallost: { main: string };
    success600: { main: string };
    low: {
      main: string;
      light: string;
      lightest: string;
      contrastText: string;
      border: string;
      background: string;
      text: string;
      fill: string;
    };
    normal: {
      main: string;
      light: string;
      lightest: string;
      contrastText: string;
      border: string;
      background: string;
      text: string;
      fill: string;
    };
    hover: { main: string };
  }
  interface PaletteOptions {
    brand200: { main: string };
    elevation01: { main: string };
    signallost: { main: string };
    elevation02: { main: string };
    hover: { main: string };
  }
}

export const font = "'Open Sans', sans-serif";

export const getDesignTokens = (mode: PaletteMode, palette: any) => ({
  typography: {
    fontFamily: font,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 750,
      md: 900,
      lg: 1200,
      xl: 3000,
    },
  },
  components: {
    MuiCheckbox: {
      ...(mode === 'light'
        ? {
            styleOverrides: {
              root: {
                '&.MuiCheckbox-root': {
                  color: '#4D5258',
                },
              },
            },
          }
        : {
            styleOverrides: {
              root: {
                '&.MuiCheckbox-root': {
                  color: '#fff',
                },
              },
            },
          }),
    },
    MuiSwitch: {
      ...(mode === 'light'
        ? {
            styleOverrides: {
              root: {
                '& .MuiSwitch-switchBase': {
                  padding: 0,
                  transitionDuration: '300ms',
                  left: '4px',
                  color: '#FFFFFF',
                  '&.Mui-checked': {
                    transform: 'translateX(16px)',
                    color: '#FFFFFF',
                    left: '2px!important',
                    '& + .MuiSwitch-track': {
                      backgroundColor: '#12488A',
                      opacity: 1,
                      border: 0,
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                      opacity: 0.5,
                    },
                  },
                },
                '& .MuiSwitch-thumb': {
                  boxSizing: 'border-box',
                  width: '12px',
                  height: '12px',
                  marginTop: '3.5px',
                },
                '& .MuiSwitch-track': {
                  borderRadius: 26 / 2,
                  backgroundColor: '#E1E4EB',
                  opacity: 1,
                  transition: 'background 0.5s ease',
                },
              },
            },
          }
        : {
            styleOverrides: {
              root: {
                '& .MuiSwitch-switchBase': {
                  padding: 0,
                  transitionDuration: '300ms',
                  left: '4px',
                  color: '#E1E4EB',
                  '&.Mui-checked': {
                    transform: 'translateX(16px)',
                    color: '#12488A',
                    left: '2px!important',
                    '& + .MuiSwitch-track': {
                      backgroundColor: '#9CC8FF',
                      opacity: 1,
                      border: 0,
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                      opacity: 0.5,
                    },
                  },
                },
                '& .MuiSwitch-thumb': {
                  boxSizing: 'border-box',
                  width: '12px',
                  height: '12px',
                  marginTop: '3.5px',
                },
                '& .MuiSwitch-track': {
                  borderRadius: 26 / 2,
                  backgroundColor: '#6C727A',
                  opacity: 1,
                  transition: 'background 0.5s ease',
                },
              },
            },
          }),
    },
    MuiTab: {
      ...(mode === 'light'
        ? {
            styleOverrides: {
              root: {
                '&.Mui-selected': { color: '#0E57B0' },
              },
            },
          }
        : {
            styleOverrides: {
              root: {
                '&.Mui-selected': {
                  color: '#9CC8FF',
                },
              },
            },
          }),
    },
    MuiOutlinedInput: {
      ...(mode === 'light'
        ? {
            styleOverrides: {
              root: {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#4D5258',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#4D5258',
                  borderWidth: '1px',
                },
              },
            },
          }
        : {
            styleOverrides: {
              root: {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#fff',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#fff',
                  borderWidth: '1px',
                },
              },
            },
          }),
      // .css-1r123kj-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline
    },
    MuiInputLabel: {
      ...(mode === 'light'
        ? {
            styleOverrides: {
              root: {
                '&.MuiFormLabel-root': {
                  color: '#4D5258',
                },
              },
            },
          }
        : {
            styleOverrides: {
              root: {
                '&.MuiFormLabel-root': {
                  color: '#fff',
                },
              },
            },
          }),
    },
    MuiTooltip: {
      ...(mode === 'light'
        ? {
            styleOverrides: {
              tooltip: {
                color: '#F7F9FC',
                backgroundColor: '#353A41',
              },
            },
          }
        : {
            styleOverrides: {
              tooltip: {
                color: '#F0F2F5',
                backgroundColor: '#353A41',
              },
            },
          }),
    },
    MuiAutocomplete: {
      ...(mode === 'light'
        ? {
            styleOverrides: {
              root: {
                '.MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #BCC2CC!important',
                },
              },
            },
          }
        : {
            styleOverrides: {
              root: {
                '.MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #BCC2CC',
                },
              },
            },
          }),
    },
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: {
            main: '#12488A',
            light: '#F7FBFF',
            dark: '#001733',
          },
          background: {
            default: '#F7F9FC',
            paper: '#FFFFFF',
          },
          text: {
            primary: '#4D5258',
            secondary: '#6C727A',
            disabled: '#BCC2CC',
          },
          divider: '#E1E4EB',
          brand200: palette.augmentColor({
            color: {
              main: '#E0EDFF',
            },
          }),
          neutral500: palette.augmentColor({
            color: {
              main: '#878D96',
            },
          }),
          hover: palette.augmentColor({
            color: {
              main: '#F0F2F5',
            },
          }),
          elevation01: palette.augmentColor({
            color: {
              main: '#FFF',
            },
          }),
          elevation02: palette.augmentColor({
            color: {
              main: '#F7F9FC',
            },
          }),
          signallost: palette.augmentColor({
            color: {
              main: '#EDF7FF',
            },
          }),
          success600: palette.augmentColor({
            color: {
              main: '#0D852D',
            },
          }),
          normal: {
            border: '#0D852D',
            background: '#EDFFF1',
            text: '#003B0D',
            fill: '#0D852D',
          },
          low: {
            main: '#F0F2F5',
            light: '#E1E4EB',
            contrastText: '#202429',
            border: '#FA9519',
            background: '#FFF8ED',
            text: '#612F00',
            fill: '#FA9519',
          },
          warning: {
            main: '#FFF8ED',
            light: '#FFE5BF',
            contrastText: '#612F00',
          },
          grey: {
            50: '#353A41',
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: '#9CC8FF',
            light: '#E0EDFF',
            dark: '#012D63',
          },
          background: {
            default: '#202429',
            paper: '#2F343A',
          },
          text: {
            primary: '#F0F2F5',
            secondary: '#BCC2CC',
            disabled: '#878D96',
          },
          divider: '#353A40',
          brand200: palette.augmentColor({
            color: {
              main: '#E0EDFF',
            },
          }),
          neutral500: palette.augmentColor({
            color: {
              main: '#878D96',
            },
          }),
          hover: palette.augmentColor({
            color: {
              main: '#353A40',
            },
          }),
          elevation01: palette.augmentColor({
            color: {
              main: '#2A2E34',
            },
          }),
          elevation02: palette.augmentColor({
            color: {
              main: '#2F343A',
            },
          }),
          signallost: palette.augmentColor({
            color: {
              main: '#BFE4FF',
            },
          }),
          success600: palette.augmentColor({
            color: {
              main: '#59D478',
            },
          }),
          normal: {
            border: '#0D852D',
            background: '#EDFFF1',
            text: '#003B0D',
            fill: '#026E1D',
          },
          low: {
            main: '#E1E4EB',
            light: '#BCC2CC',
            lightest: '#E1E4EB',
            contrastText: '#202429',
            border: '#B26205',
            background: '#FFF8ED',
            text: '#612F00',
            fill: '#B26205',
          },
          warning: {
            main: '#FFE5BF',
            light: '#FFCA80',
            contrastText: '#612F00',
          },
          grey: {
            50: '#353A41',
          },
        }),
  },
});
