import './App.css';
import { createContext, useMemo, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getDesignTokens, font } from '../theme';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import { getConfig } from '../constants';
import { loggedIn, loggedOut } from '../features/user/userSlice';
import { useAppDispatch } from './hooks';
import { useKeycloak } from '@react-keycloak/web';

declare global {
  interface Window {
    pendo: any;
  }
}

export const ColorModeContext = createContext({
  toggleColorMode: (mode: PaletteMode) => {
    return mode;
  },
});

function App() {
  const [mode, setMode] = useState<PaletteMode>('light');
  const dispatch = useAppDispatch();
  const { keycloak } = useKeycloak();
  const { palette } = createTheme();
  const theme = useMemo(
    () => createTheme(getDesignTokens(mode, palette)),
    [mode],
  );
  const colorMode = useMemo(
    () => ({
      toggleColorMode: (mode: PaletteMode) => {
        setMode(mode);
        return mode;
      },
    }),
    [],
  );

  const isLoggedIn = keycloak.authenticated;
  const token = keycloak.token;

  const isAuthEnabled = getConfig().AUTH_ENABLED;

  if (isAuthEnabled && (!keycloak || !isLoggedIn || !token)) {
    return null;
  } else {
    if (
      keycloak?.tokenParsed?.exp !== undefined &&
      keycloak?.tokenParsed?.exp < Date.now() / 1000
    ) {
      dispatch(loggedOut());
      keycloak.logout({
        redirectUri: window.location.origin,
      });
      return null;
    }
    dispatch(loggedIn({ token }));
  }

  return (
    <HelmetProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <div style={{ fontFamily: font }} className="App">
            <Helmet>
              <title>Core Communities Management</title>
            </Helmet>
            <header className="App-header">
              <Navbar />
            </header>
            <Outlet />
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </HelmetProvider>
  );
}

export default App;
