import { Box, Typography } from '@mui/material';

const ZoneManagement = () => {
  return (
    <Box>
      <Typography data-testid="zone-management-text">ZoneManagement</Typography>
    </Box>
  );
};

export default ZoneManagement;
