/* eslint-disable */
//Not ideal but this is mostly just bootsrap code for tests/local running without auth outside of the actual live keycloak instance
import Keycloak, { KeycloakAdapter, KeycloakLoginOptions } from 'keycloak-js';
import { getConfig } from '../../constants';
export const keycloak = new Keycloak({
  url: getConfig().KEYCLOAK_URL,
  realm: getConfig().KEYCLOAK_REALM,
  clientId: getConfig().KEYCLOAK_CLIENT_ID,
});

function createPromise() {
  // Need to create a native Promise which also preserves the
  // interface of the custom promise type previously used by the API
  const p: any = {
    setSuccess: function (result: any) {
      p.resolve(result);
    },

    setError: function (result: any) {
      p.reject(result);
    },
  };
  p.promise = new Promise(function (resolve, reject) {
    p.resolve = resolve;
    p.reject = reject;
  });

  p.promise.success = function (callback: any) {
    this.then(function handleSuccess(value: any) {
      callback(value);
      return;
    });

    return this;
  };

  p.promise.error = function (callback: any) {
    this.catch(function handleError(error: any) {
      callback(error);
      return;
    });

    return this;
  };

  return p;
}

// Implement the 'KeycloakAdapter' interface so that all required methods are guaranteed to be present but not functional for tests
export const KeycloakTestAdapter: KeycloakAdapter = {
  login(options?: KeycloakLoginOptions) {
    return createPromise();
  },
  logout(options?: KeycloakLoginOptions) {
    return createPromise();
  },
  register(options?: KeycloakLoginOptions) {
    return createPromise();
  },
  accountManagement(options?: KeycloakLoginOptions) {
    return createPromise();
  },
  redirectUri(options?: KeycloakLoginOptions) {
    return createPromise();
  },
};
