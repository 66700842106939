/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/App';
import './index.css';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import './i18n/i18n';
import ErrorPage from './components/Shared/ErrorPage';
import Dashboard from './pages/Dashboard';
import ZoneManagement from './pages/ZoneManagement';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { keycloak, KeycloakTestAdapter } from './app/Auth/Keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { getConfig } from './constants';

function startApp() {
  const container = document.getElementById('root')!;
  const root = createRoot(container);

  const router = createBrowserRouter([
    ...createRoutesFromElements(
      <Route path="/" element={<App />} errorElement={<ErrorPage />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="zoneManagement" element={<ZoneManagement />} />
      </Route>,
    ),
    {
      path: '/error',
      element: <ErrorPage />,
    },
  ]);

  const isAuthEnabled = getConfig().AUTH_ENABLED;

  let kcInitOptions = null;
  if (!isAuthEnabled) {
    kcInitOptions = {
      ...{ adapter: KeycloakTestAdapter },
      checkLoginIframe: true,
    };
  } else {
    kcInitOptions = { onLoad: 'login-required', checkLoginIframe: false };
  }

  root.render(
    // <React.StrictMode> -- this needs to be off
    <Provider store={store}>
      <ReactKeycloakProvider authClient={keycloak} initOptions={kcInitOptions}>
        <RouterProvider router={router} />
      </ReactKeycloakProvider>
    </Provider>,
    // </React.StrictMode>,
  );
}

startApp();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
