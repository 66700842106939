import { Box, AppBar, Toolbar, Tabs, Tab, Typography } from '@mui/material';
import { useState } from 'react';
import TabContainer from '../../components/Shared/TabContainer';
import {
  DashboardFillIcon,
  DashboardOutlineIcon,
  LotMapIcon,
} from '../../components/Shared/Icons';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [tabValue, setTabValue] = useState<number | boolean>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const pages = [
    {
      name: 'Dashboard',
      path: '/dashboard',
      icon:
        tabValue === 0 ? (
          <DashboardFillIcon viewBox="0 -2 20 24" />
        ) : (
          <DashboardOutlineIcon viewBox="0 -2 20 24" />
        ),
    },
    {
      name: 'Zone Management',
      path: '/zoneManagement',
      icon: <LotMapIcon viewBox="0 0 20 20" />,
    },
  ];

  return (
    <Box
      className="navbar"
      sx={{ display: 'flex', minHeight: { xs: '79px', md: '97.25px' } }}
    >
      <AppBar
        position="fixed"
        sx={(theme) => ({
          padding: '10px 0',
          background: theme.palette.elevation01?.main,
          boxShadow: 0,
          zIndex: 2,
        })}
      >
        <Toolbar>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box>
              <Typography
                sx={{ color: '#000', fontWeight: 'bold', fontSize: '40px' }}
              >
                Logo
              </Typography>
            </Box>
            <Box>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                centered
                sx={{
                  width: '100%',
                  maxWidth: '100%',
                  zIndex: 1,
                }}
                TabIndicatorProps={{ style: { display: 'block' } }}
              >
                {pages.map((item: any, index: number) => (
                  <Tab
                    data-testid={`navtab-${item.name}`}
                    disableRipple
                    sx={{
                      minWidth: { xs: 70, sm: 100, md: 60, lg: 100 },
                      letterSpacing: { xs: '-.0001em', sm: '0' },
                      paddingLeft: { xs: '2px', sm: '5px' },
                      paddingRight: { xs: '2px', sm: '5px' },
                      fontSize: { xs: '10px', sm: '13px' },
                    }}
                    {...(item.path ? { component: Link, to: item.path } : {})}
                    onClick={(e) =>
                      item?.onClick
                        ? item?.onClick(
                            item.onClickParam ? item.onClickParam : e,
                          )
                        : e
                    }
                    key={index}
                    label={
                      item.icon ? (
                        <TabContainer
                          title={item.name}
                          icon={item.icon}
                          index={index}
                          tabValue={tabValue}
                        />
                      ) : (
                        <Box
                          sx={{
                            textTransform: 'capitalize',
                            fontSize: '14px',
                            fontWeight: '600',
                          }}
                          data-testid={`${item.name}-tab`}
                        >
                          {item.name}
                        </Box>
                      )
                    }
                    disabled={item?.disabled}
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
