import { Box, Typography } from '@mui/material';

const Dashboard = () => {
  return (
    <Box>
      <Typography data-testid="dashboard-text" sx={{ color: '#000' }}>
        Dashboard
      </Typography>
    </Box>
  );
};

export default Dashboard;
