import { isTruthy } from './utils';

export function getConfig() {
  return {
    URL: process.env.REACT_APP_URL || 'http://localhost:3004/api/v1',
    AUTH_ENABLED: isTruthy(process.env.REACT_APP_AUTH_ENABLED),
    IS_PLAYWRIGHT: isTruthy(process.env.REACT_APP_IS_PLAYWRIGHT) || false,
    KEYCLOAK_URL: process.env.REACT_APP_KEYCLOAK_URL,
    KEYCLOAK_CLIENT_ID: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
    KEYCLOAK_REALM: process.env.REACT_APP_KEYCLOAK_REALM || '',
  };
}
