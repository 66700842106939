import { Box } from '@mui/material';

export type TabContainerProps = {
  title: string;
  icon: JSX.Element;
  index: number;
  tabValue: number | boolean;
};

export default function TabContainer({
  title,
  icon,
  index,
  tabValue,
}: TabContainerProps) {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: theme.palette.text.primary,
      })}
      data-testid={`${title}-tab`}
    >
      <Box
        data-testid="navbar-tab-icon"
        sx={(theme) => ({
          marginBottom: '5px',
          borderRadius: '16px',
          width: '56px',
          height: '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: index === tabValue ? theme.palette?.brand200?.main : '',
          color: index === tabValue ? theme.palette.primary.main : '',
          ':hover': {
            bgcolor: index !== tabValue ? theme.palette.hover?.main : '',
          },
        })}
      >
        {icon}
      </Box>
      <Box
        data-testid="navbar-tab-text"
        sx={(theme) => ({
          textTransform: 'capitalize',
          color: index === tabValue ? theme.palette.primary.main : '',
        })}
      >
        {title}
      </Box>
    </Box>
  );
}
