import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const initialUserState: any = {
  token: null,
  name: '',
  search: '',
};

export type LoginPayload = {
  token?: string;
};

export type SetSearchPayload = {
  search: string;
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    loggedIn(state, action: PayloadAction<LoginPayload>) {
      state.token = action.payload?.token;
    },
    loggedOut(state) {
      state.token = null;
    },
    setSearch(state, action: PayloadAction<SetSearchPayload>) {
      state.search = action.payload.search;
    },
  },
});

export const { loggedIn, loggedOut, setSearch } = userSlice.actions;
export default userSlice.reducer;

export const selectCurrentSearch = (state: RootState) => state.user.search;
